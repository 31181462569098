<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col
          cols="12"
          class="details-part d-flex align-center justify-center"
        >
          <v-row
            no-gutters
            class="align-start"
          >
              <v-col
                cols="12"
                class="details-part d-flex align-center justify-center flex-column"
              >
                <v-card
                  min-width="500px"
                >
                <v-col>
                  <div class="pt-md-4 pt-0 ma-10">
                    <v-container>
                      <v-row class="flex-column">
                        <v-col>
                          <p class="login-slogan display-1 text-center font-weight-medium mt-5">
                            Login to proceed
                          </p>
                        </v-col>
                        <v-alert type="error" dismissible v-show="form.error">
                          {{ form.error }}
                        </v-alert>
                        <v-form
                        ref="form"
                        method="post"
                        action="/"
                        lazy-validation
                        @submit.prevent="login()">
                          <v-col>
                            <v-text-field
                              outlined
                              v-model="form.email"
                              :rules="emailRules"
                              value="admin@flatlogic.com"
                              label="Email"
                              required
                            ></v-text-field>
                            <small
                                v-show="form.errors.has('email')"
                                class="validation-error"
                                >{{ form.errors.get('email') }}</small>
                            <v-text-field
                              outlined
                              v-model="form.password"
                              :rules="passRules"
                              type="password"
                              label="Password"
                              required
                            ></v-text-field>
                            <small
                                v-show="form.errors.has('password')"
                                class="validation-error"
                                >{{
                                form.errors.get('password')
                            }}</small>
                          </v-col>
                          <v-col>
                            <v-btn
                              class="text-capitalize col-12"
                              large
                              color="primary"
                              type="submit"
                              :loading="form.busy"
                            >
                              Log in
                            </v-btn>
                          </v-col>
                        </v-form>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import validationRules from '@/mixins/validationRules'
import Form from 'vform'

export default {
  name: 'Login',
  mixins: [validationRules],
  data() {
    return {
      form: new Form({
        email: '',
        password: '',
      }),
    }
  },
  computed: {
    ...mapGetters(['userAuthenticated', 'backendBaseUrl']),
  },
  mounted() {
    if (this.userAuthenticated) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    ...mapActions(['authenticateUser']),
    login() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form.busy = true
        axios.post(this.backendBaseUrl + 'auth/login', {
          email: this.form.email,
          password: this.form.password
        })
        .then(response => {
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
          const userInfo = {
            id: response.data.user.id,
            username: response.data.user.name,
            accessToken: response.data.token,
          }
          this.authenticateUser(userInfo)
          this.form.busy = false
          this.$router.push({ name: 'home' })
        })
        .catch(error => {
          console.log(error);
          this.form.error = error.response.data.message
          this.form.busy = false
        });
      }
    },
  },
}

</script>

