<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <h3>Viper</h3>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log(error.response.status)
        const url = error.request.responseURL
        const match = url.match(/^(?:https?:)?(?:\/\/)?([^\/\?]+)/i);
        const hostname = match && match[1];

        if (error.response.status === 401 && !this.isWhiteListedHostname(hostname)) {
          this.$toast.info('Your session has expired, please login')
          this.clearUser()
          this.$router.push('/login')
        }

        return Promise.reject(error)
      },
    )
  },
  
  methods: {
    ...mapActions(['clearUser']),
    whiteListedHostnames() {
      return [
        'api.betika.com',
        'api.betafriq.co.ke',
        'api.spotika.co.ke']
        
    },
    isWhiteListedHostname(hostname) {
      return this.whiteListedHostnames().includes(hostname)
    },
  }
}
</script>