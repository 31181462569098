export default {
  data: () => ({
    nameRules: [(v) => !!v || "Name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    passRules: [
      (v) => !!v || "Password is required",
    ],
  }),
};
