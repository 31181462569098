import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const CLEAR_USER = "CLEAR_USER";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    userAuthenticated: false,
    userInfo: {
      id: "",
      name: "",
      accessToken: "",
    },
    backendBaseUrl: process.env.VUE_APP_BACKEND_BASE_URL,
    BtkBaseUrl: "https://api.betika.com/",
    betafriqBaseUrl: "https://api.betafriq.co.ke/",
    spotikaBaseUrl: "https://api.spotika.co.ke/",
    OdiBaseUrl: "https://odibets.com/api/",
  },
  getters: {
    backendBaseUrl: (state) => state.backendBaseUrl,
    betafriqBaseUrl: (state) => state.betafriqBaseUrl,
    spotikaBaseUrl: (state) => state.spotikaBaseUrl,
    BtkBaseUrl: (state) => state.BtkBaseUrl,
    OdiBaseUrl: (state) => state.OdiBaseUrl,
    BtkToken: (state) => state.BtkToken,
    userAuthenticated: (state) => state.userAuthenticated,
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    [AUTHENTICATE_USER](state, payload) {
      state.userAuthenticated = true;
      state.userInfo = payload;
    },
    [CLEAR_USER](state) {
      state.userAuthenticated = false;
      state.userInfo = {};
    },
  },
  actions: {
    authenticateUser({ commit }, payload) {
      commit(AUTHENTICATE_USER, payload);
    },
    clearUser({ commit }) {
      commit(CLEAR_USER);
    },
  },
  modules: {},
});
